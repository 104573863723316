
export enum AssetEuroNorm {
  Euro0= 'Euro0',
  Euro1= 'Euro1',
  Euro2= 'Euro2',
  Euro3= 'Euro3',
  Euro4= 'Euro4',
  Euro5= 'Euro5',
  Euro6= 'Euro6',

}